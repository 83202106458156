<template>
    <div>
        <qrcode-vue :value="qrValue" :size="size" level="H" ></qrcode-vue>
    </div>
  </template>
  
  <script>
  import QrcodeVue from 'qrcode.vue'
  
  export default {
    components: {
        QrcodeVue,
    },
    data() {
        return {
            size: 200,
        }
    },
    props: {
      qrValue: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any styles you need */
  </style>